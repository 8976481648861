// import Nunito and opensans
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;1,600&family=Open+Sans&display=swap');

$background-color: #f4f4f4;
$text-color: black;
$title-font-family: 'Nunito';
$font-family: 'Open Sans';
$transition: 250ms;
// $border-radius: 25px;
// $box-shadow: 20px 20px 60px #686868, -20px -20px 60px #8c8c8c;
