@import 'Styles/_variables';

.App {
  text-align: center;
  background: $background-color;
  height: 100vh;
  overflow: hidden;
  color: $text-color;
  font-family: $font-family;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-family: $title-font-family;
  }

  .search {
    width: 35vw;
    min-width: 400px;
    max-width: 600px;
    min-height: 80px;
    height: 7vh;
    background: white;
    transition: $transition;
    box-shadow: 0px 10px 20px 0px lightgray;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    textarea:focus,
    input:focus {
      outline: none;
    }

    input[type='text'] {
      border: none;
      color: $text-color;
      height: 100%;
      width: 70%;
      font-size: 1.2em;
      font-family: $title-font-family;
      padding: 0 5%;
    }

    button[type='submit'] {
      width: 30%;
      height: 100%;
      border: none;
      color: lighten($text-color, 60);
      font-size: 2em;
      background: transparent;

      &:hover {
        color: $text-color;
        cursor: pointer;
      }
    }

    &:hover {
      box-shadow: 0px 10px 20px 0px darken(lightgray, 5);
    }
  }
}
